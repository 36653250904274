import { twMerge } from 'tailwind-merge';

import type { PropsWithChildren } from 'react';

export interface TypographyProps extends PropsWithChildren {
  className?: string;
}

export function H1({ className, children }: TypographyProps) {
  return (
    <h1
      className={twMerge(
        'font-theme-title-weight scroll-m-20 text-theme-display-weight text-xl text-text-primary tracking-wider leading-relaxed',
        className
      )}
    >
      {children}
    </h1>
  );
}

export function H2({ className, children }: TypographyProps) {
  return (
    <h2
      className={twMerge(
        'scroll-m-20 text-base tracking-wide leading-relaxed transition-colors first:mt-0',
        className
      )}
    >
      {children}
    </h2>
  );
}

export function H3({ className, children }: TypographyProps) {
  return (
    <h3 className={twMerge('scroll-m-20 text-base', className)}>{children}</h3>
  );
}
export function H4({ className, children }: TypographyProps) {
  return (
    <h4 className={twMerge('scroll-m-20 text-base', className)}>{children}</h4>
  );
}

export function Paragraph({ className, children }: TypographyProps) {
  return <p className={className}>{children}</p>;
}
